module.exports = [{
      plugin: require('/Users/marko/Desktop/m2/vatragel/node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/Users/marko/Desktop/m2/vatragel/src/intl","languages":["en","cs","sk"],"defaultLanguage":"sk"},
    },{
      plugin: require('/Users/marko/Desktop/m2/vatragel/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5WHN5VS","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"gtmAuth":"YOUR_GOOGLE_TAGMANAGER_ENVIRONMENT_AUTH_STRING","gtmPreview":"YOUR_GOOGLE_TAGMANAGER_ENVIRONMENT_PREVIEW_NAME","dataLayerName":"YOUR_DATA_LAYER_NAME"},
    },{
      plugin: require('/Users/marko/Desktop/m2/vatragel/node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-145478259-1","head":false,"anonymize":true},
    },{
      plugin: require('/Users/marko/Desktop/m2/vatragel/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
